* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

@font-face {
  font-family: "Gilroy-Light";

  src: url("./assets/fonts/Gilroy-Light.otf") format("truetype");
}
@font-face {
  font-family: "Gilroy-Medium";

  src: url("./assets/fonts/Gilroy-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Gilroy-Bold";

  src: url("./assets/fonts/Gilroy-Bold.ttf") format("truetype");
}

ul {
  list-style: none;
}

.Divider {
  width: 445px;
  height: 0.5px;
  flex-grow: 0;
  margin: 16.5px 0 24px;
  background-color: #e5e8ed;
}

.optionsHeader {
  font-family: "Gilroy-Bold";
  font-size: 18px;
  margin-bottom: 16px;
}

.LoaderContainer {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 574px;
  width: 100%;
  background-color: white;
  top: 0;
}

body {
  max-width: 1200px;
  overflow-x: hidden;
}
.App {
  display: flex;
  flex-direction: column;
  font-family: "Gilroy-Light";
  /* align-items: center; */
}

.TabBar {
  margin-left: auto;
  margin-right: auto;
  margin-top: 32px;
  border: solid 0px #c0c0c0;
  border-right-width: 0.5px;
}

.TabBar ul {
  display: flex;
  font-weight: 500;
  list-style: none;
}

.TabBar li {
  cursor: pointer;
  border: solid 0.5px #c0c0c0;
  padding: 12px 0px;
  border-right-width: 0px;
  font-family: "Gilroy-Bold";
  font-size: 20px;
  font-weight: bold;
  color: #5e5e5e;
  width: 138px;
  display: flex;
  justify-content: center;
  transition: 0.25s;
  background-color: white;
}

.TabBar li:hover {
  opacity: 0.7;
  /* transform: scale(1.1); */
  font-size: 19px;
}
.SelectedTab {
  background-color: #0089cf !important;
  color: white !important;
  border: solid 1px #00aeef !important;
}
.threeDViewer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.threeDViewer p {
  margin: 4px 0px 0px 0px;
  font-family: "Gilroy-Light";
  font-size: 14px;
}
.VolumeSelectionTab ul {
  /* font-weight: 500; */
  list-style: none;

  display: flex;
  flex-wrap: wrap;
}

.VolumeSelectionTab li {
  width: 5.5rem;
  cursor: pointer;
  border-style: solid;
  border-width: 1px;
  border-color: #acacac;
  margin-right: 1rem;
  padding: 12px 14px 12px 13px;
  font-family: "Gilroy-Light";
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  transition: 0.25s;
}

.VolumeSelectionTab li:hover {
  transform: scale(1.1);
}

.LayerSelectionBar {
  font-family: "Gilroy-Bold";
}
.LayerSelectionBar div {
  font-size: 14px;
  font-weight: 300;
  color: #333333;
  text-align: center;
  font-family: "Gilroy-Light";
  margin-right: 1rem;
}

.LayerSelectionBar li {
  width: 70px;
  height: 50px;
  flex-grow: 0;
  margin: 0 0 4.8px;
  cursor: pointer;
  border-style: solid;
  border-width: 1px;
  border-color: #acacac;
  padding: 0px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.25s;
}
.LayerSelectionBar li:hover {
  transform: scale(1.1);
}
.LayerSelectionBar ul {
  font-weight: 500;
  list-style: none;
  display: flex;
  flex-direction: row;
}

.flex-row {
  display: flex;
  justify-content: space-between;

  margin-top: 4rem;
  margin-left: 4rem;
}

.LayersInfo {
  min-height: 16rem;
}

.LayersInfo li {
  /* margin-bottom: 0.5rem; */
  color: #666666;
  font-weight: 600;
  width: max-content;
  font-size: 1rem;
}
.ColorSelectionBar {
  /* margin-top: 16px; */
  font-family: "Gilroy-Bold";
}

.ColorSelectionBar ul {
  display: flex;
  flex-direction: row;
}
.ColorPalette {
  width: 70px;
  height: 50px;
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.25s;
}
.ColorPalette:hover {
  transform: scale(1.1);
}
.MaterialSelectionBar {
  margin-top: 3rem;
  width: 300px;
}

.MaterialSelectionBar ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.MaterialPalette {
  width: 5rem;
  aspect-ratio: 1.7;
  margin-right: 1rem;
  margin-top: 0.3rem;
  margin-bottom: 0.5rem;
}

.ProductView {
  flex-grow: 100;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-right: 64px;
}
.viewerContainer {
  width: 545px;
  height: 574px;
}
.threedee-container {
  width: 505px;
  height: 525px;
  display: block;
  position: relative;
  background-color: white;
}

.SlicedView {
  width: 545px;
  height: 540px;
  display: flex;
  justify-content: center;

  position: relative;
  background-color: rgb(255, 255, 255);
}
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

._2DViewer {
  display: flex;
  justify-content: center;
  position: relative;
}
.Thumbnail {
  width: 85px;
  height: 85px;
  margin-bottom: 16px;
  cursor: pointer;
  border: solid 1px #efefef;
  background-color: #ffffff;
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Thumbnail img {
  height: 75px;
}

.selectedThumbnail {
  border: solid 1px #0089cf;
}
.selectedImage {
  flex-grow: 0;
  background-color: #ffffff;
  position: relative;
}
.selectedImage img {
  width: 416px;
  height: 545px;
  width: 545px;
  height: 574px;
  flex-grow: 0;
  background-color: #ffffff;
  object-fit: contain;
}
.ThumbnailContainer {
  position: absolute;
  /* position: fixed; */
  left: -45px;
  z-index: 10;
}

.RotatorIcon {
  margin-top: 8px;
}
._3DViewerFooter {
  font-family: "Gilroy-Light";
}

.DisabledColorPalette {
  cursor: not-allowed;
  opacity: 0.5;
  pointer-events: none;
}

.LayerDescription {
  display: flex;
  /* align-items: center; */
  flex-wrap: wrap;
  flex-direction: column;
}
.LayerDescription li {
  width: max-content;
}
.LayerDescription p {
  font-size: 0.9rem;
  margin-left: 7px;
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  color: #9c9c9c;
  font-weight: 600;
  padding-left: 0.5rem;
  max-width: 32rem;
}
@media only screen and (min-width: 1200px) {
  .App {
    align-items: center;
  }
}
@media only screen and (max-width: 850px) {
  .LayerDescription p {
    padding-left: 0rem;
    max-width: 85vw;
    margin-left: 12px;
  }
  .LoaderContainer {
    width: 90vw;
    /* height: 60vh; */
  }
  .Divider {
    width: auto;
    margin: 16px 8px;
  }
  .optionsHeader {
    font-family: "Gilroy-Bold";
    font-size: 16px;
    color: #0089cf;
  }
  .viewerContainer {
    width: 100vw;
    /* height: 70vh; */
    margin: 0px;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  .selectedImage {
    width: 100vw;
    height: 487px;
  }
  .ThumbnailContainer {
    position: relative;
    left: 0px;
    display: flex;
    width: 90vw;
    margin-top: 16px;
    /* justify-content: space-between; */
    align-content: space-between;
  }
  .Thumbnail {
    margin-right: 16px;
    width: 23vw;
  }
  .selectedImage img {
    width: 98vw;
    height: 487px;
    flex-grow: 0;
    background-color: #ffffff;
    object-fit: contain;
  }
  ._2DViewer {
    flex-direction: column-reverse;
    width: 100vw;
    justify-content: center;
    align-items: center;
  }

  .threedee-container {
    width: 100vw;
  }
  .SlicedView {
    width: 90vw;
  }
  .TabBar li {
    width: 100px;
    height: max-content;
    font-size: 1rem;
    padding: 12px 24px;
    line-height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100px;
    text-align: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .VolumeSelectionTab ul {
    display: flex;
    flex-wrap: wrap;
  }
  .VolumeSelectionTab li {
    font-size: small;
    border-bottom-width: 1px;
    /* max-width: 4rem; */
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    font-size: small;
    padding: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    width: 3.1rem;
    margin-right: 12px;
    margin-bottom: 1rem;
  }

  .VolumeSelectionTab p {
    margin-top: 16px;
  }
  .flex-row {
    flex-direction: column;
    margin: 0px;
    margin-top: 1rem;
  }
  .ProductView {
    margin-top: 0rem;
    margin-right: 0px;
  }
  .LayerSelectionBar {
    margin-top: 1rem;
  }
  .LayersInfo {
    margin-top: 1rem;
    min-height: 17rem;
  }

  .ColorSelectionBar {
    margin-top: 1rem;
  }
  .RightSection {
    display: flex;
    flex-direction: column;
    margin: 16px;
  }

  #viewport-controls button {
    padding-bottom: 1.25rem !important;
  }
}

/* rotating animation */

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}
